export const EXT = {
  GIF: 'image/gif',
  ICO: 'image/vnd.microsoft.icon',
  SVG: 'image/svg+xml',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  TIFF: 'image/tiff',
  BMP: 'image/bmp',
  PSD: 'image/vnd.adobe.photoshop',
  PSD1: 'application/x-photoshop',
  PSD2: 'application/octet-stream',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  RTF: 'application/rtf',
  TXT: 'text/plain',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PDF: 'application/pdf',
  XML: 'application/xml',
  XML1: 'text/xml',
  CSS: 'text/css',
  P7S: 'application/x-pkcs7-signature',
  ZIP: 'application/zip',
  BZ: 'application/x-bzip',
  BZ2: 'application/x-bzip2',
  TAR: 'application/x-tar',
  a7z: 'application/x-7z-compressed',
  GZ: 'application/gzip',
  JAR: 'application/java-archive',
  RAR: 'application/vnd.rar',
  AVI: 'video/x-msvideo',
  MP3: 'audio/mpeg',
  WAV: 'audio/wav',
  MPEG: 'video/mpeg',
  MP4: 'video/mp4',
  MP4_ZOOM: 'MP4',
  M4A_ZOOM: 'M4A',
  CHAT_ZOOM: 'CHAT',
  XXX: 'xxx'
}
export const COLOR = {
  IMAGE: '#E54887',
  VIDEO: 'red',
  AUDIO: 'green',
  DOCUMENT: '#0077DB',
  TABLE: '#04AA42',
  PRESENT: '#FAC031',
  MARKUP: '#003DB7',
  ARCHIV: '#883888',
  UNKNOWN: '#F95721',
  XXX: '#6E7782'
}
